// Please see documentation at https://docs.microsoft.com/aspnet/core/client-side/bundling-and-minification
// for details on configuring this project to bundle and minify static web assets.


/**
 * @constructor
 */
function WindowSizeMiddleware () {
    var w = window,
        d = document,
        e = d.documentElement,
        g = d.getElementsByTagName('body')[0];//,
    //x = w.innerWidth || e.clientWidth || g.clientWidth,
    //y = w.innerHeight || e.clientHeight || g.clientHeight;

    this.getWidth = function () {
        var w1 = w.innerWidth || e.clientWidth || g.clientWidth;
        return w1;
    };
    this.getHeight = function () {
        var h1 = w.innerHeight || e.clientHeight || g.clientHeight;
        return h1;
    };
    //console.log(x + ' × ' + y);
}

/**
 * @constructor
 */
function ObserverList () {
    this.observerList = [];
}
/**
 * 
 * @param {*} obj 
 * @returns {number}
 */
ObserverList.prototype.add = function (obj) {
    return this.observerList.push(obj);
};

ObserverList.prototype.count = function () {
    return this.observerList.length;
};

/**
 * 
 * @param {number} index 
 * @returns { * | undefined}
 */
ObserverList.prototype.get = function (index) {
    if (index > -1 && index < this.observerList.length) {
        return this.observerList[index];
    }
};

ObserverList.prototype.indexOf = function (obj, startIndex) {
    var i = startIndex;

    while (i < this.observerList.length) {
        if (this.observerList[i] === obj) {
            return i;
        }
        i++;
    }

    return -1;
};

ObserverList.prototype.removeAt = function (index) {
    this.observerList.splice(index, 1);
};



function Subject () {
    this.observers = new ObserverList();
    this.context = undefined;
}

Subject.prototype.addObserver = function (observer) {
    this.observers.add(observer);
    //update the newly subscribed observer on the current context
    if (this.context !== undefined) {
        observer.update(this.context);
    }
};

Subject.prototype.removeObserver = function (observer) {
    this.observers.removeAt(this.observers.indexOf(observer, 0));
};

Subject.prototype.notify = function (context) {
    this.context = context;
    var observerCount = this.observers.count();
    //setTimeout(() => {
    for (var i = 0; i < observerCount; i++) {
        try {
            this.observers.get(i).update(context);
        } catch (error) {
            console.log(error);
        }
    }
    //}, 100);
};


/**
 * @class
 * @type {Observer}
 * @param {number} suppliedId 
 * @param {Function} updateCallBackRef 
 */
function Observer (suppliedId, updateCallBackRef) {
    "use strict";
    this.id = suppliedId;
    this.update = updateCallBackRef;
    this.context = undefined;
    // this.update = function (context) {
    //     this.context = context;
    // };
    // this.notifyThePromise = function (context) {
    //     if (context !== null) {
    //         subscription(context)
    //             .then(function (result) {

    //                 //console.log(result);
    //                 // output: { brand: 'Samsung', color: 'black' }
    //             })
    //             .catch(function (error) {

    //                 //console.log(error.message);

    //             });
    //     }

    // };

}


var passiveIfSupportedMiddleware = { passive: false };
(function () {
    try {
        window.addEventListener("testPassive", null,
            Object.defineProperty({}, "passive",
                { get: function () { passiveIfSupportedMiddleware = { passive: true }; } }
            ));
        window.removeEventListener("testPassive");
    }
    catch (err) {
        //TODO: log error
    }

})();
/**
 * @constructor
 */
var GlobalMiddleware = (function () {
    function GlobalMiddleware () {
        /**
         * @type {GlobalMiddleware}
         */
        var self = this;
        this._scriptsDirectory = undefined;
        this._pResizeSubject = undefined;
        this._pWindowSizeMiddleware = window.$WindowSizeMiddleware || new WindowSizeMiddleware();
        this._serviceCollectionSingleton = {};
        this._componentSynchronizationSubject = undefined;


        GlobalMiddleware.prototype.GetDeviceDateTimeInfo = function () {
            var d2 = new Date();
            d2.getFullYear() + '-' + (d2.getMonth() + 1).toString() + '-' + d2.getDate();
            console.log(d2);
        }

        GlobalMiddleware.prototype.getScriptsDirectory = function () {

            if (self._scriptsDirectory) {
                return self._scriptsDirectory;
            } else {
                if (window.$sfConfigModel !== undefined) {
                    if (window.$sfConfigModel.EnvId === 8) {
                        self._scriptsDirectory = 'prod';
                    } else {
                        self._scriptsDirectory = 'dist';
                    }

                } else {
                    if (window.location.host.indexOf('v8builder.com') > -1) {
                        self._scriptsDirectory = 'prod';
                    } else {
                        self._scriptsDirectory = 'dist';
                    }
                }
                return self._scriptsDirectory;
            }
        }

        GlobalMiddleware.prototype.ServicesCollection = {
            TryGetOrCreateSingleton: function (className, params) {
                if (self._serviceCollectionSingleton[className] && self._serviceCollectionSingleton[className].value) {
                    return self._serviceCollectionSingleton[className].value;
                } else {
                    var ctorDelegate = window[className];
                    if (ctorDelegate) {

                        var theNewInstance = {};
                        theNewInstance.key = "$" + className;
                        theNewInstance.value = new ctorDelegate(params);
                        window[theNewInstance.key] = theNewInstance.value;
                        self._serviceCollectionSingleton[className] = theNewInstance;
                        return self._serviceCollectionSingleton[className].value;
                    } else {
                        return undefined;
                    }
                }
            },
        }
        GlobalMiddleware.prototype.SyncBroker = {
            /**
             * 
             * @param {Observer} observer 
             */
            SubscribeToSyncSubject: function (observer) {
                if (!self._componentSynchronizationSubject) {
                    self._componentSynchronizationSubject = new Subject();
                }
                self._componentSynchronizationSubject.addObserver(observer);
            },
            NotifySyncSubject: function (context) {
                if (!self._componentSynchronizationSubject) {
                    self._componentSynchronizationSubject = new Subject();
                }

                self._componentSynchronizationSubject.context = context;
            },

        }

        GlobalMiddleware.prototype.SubscribeCheckIfInViewPort =
            function (elementId, callback, ubSubscribeOnHit) {

                var theElement = document.getElementById(elementId);
                var callbackRef = callback;
                var shouldUnSubscribeOnHit = ubSubscribeOnHit;
                var theBoundScrollAction = receiveScrollNotification.bind(theElement, callbackRef);

                window.addEventListener('scroll', theBoundScrollAction, passiveIfSupported);

                function receiveScrollNotification () {

                    var isInViewPort = elementInViewport2(theElement);
                    if (isInViewPort) {
                        callbackRef();
                        if (shouldUnSubscribeOnHit) {
                            window.removeEventListener('scroll', theBoundScrollAction);
                        }
                    }
                }

                function elementInViewport2 (el) {
                    var top = el.offsetTop;
                    var left = el.offsetLeft;
                    var width = el.offsetWidth;
                    var height = el.offsetHeight;

                    while (el.offsetParent) {
                        el = el.offsetParent;
                        top += el.offsetTop;
                        left += el.offsetLeft;
                    }

                    return (
                        top < (window.pageYOffset + window.innerHeight) &&
                        left < (window.pageXOffset + window.innerWidth) &&
                        (top + height) > window.pageYOffset &&
                        (left + width) > window.pageXOffset
                    );
                }
            }
    }

    GlobalMiddleware.prototype.getWindowHeight = function () {
        return this._pWindowSizeMiddleware.getHeight();
    };

    GlobalMiddleware.prototype.getWindowWidth = function () {
        return this._pWindowSizeMiddleware.getWidth();
    };

    GlobalMiddleware.prototype.PassiveSupported = function () {
        return passiveIfSupportedMiddleware;
    };

    GlobalMiddleware.prototype.SubscribeResize = function (observer) {
        if (!this._pResizeSubject) {

            this._pResizeSubject = new Subject();

            var clientData = {};
            clientData.windowWidth = this._pWindowSizeMiddleware.getWidth();
            clientData.windowHeight = this._pWindowSizeMiddleware.getHeight();
            this._pResizeSubject.context = clientData;

            var boundEevent = handleOnResizeEvent.bind(this);

            window.addEventListener('resize', boundEevent, passiveIfSupportedMiddleware);

            this._pResizeSubject.addObserver(observer);
        } else {
            this._pResizeSubject.addObserver(observer);
        }

    };
    return GlobalMiddleware;
}());


var handleOnResizeEvent = function () {
    if (this._pResizeSubject) {
        var clientData = {};
        clientData.windowWidth = this._pWindowSizeMiddleware.getWidth();
        clientData.windowHeight = this._pWindowSizeMiddleware.getHeight();
        setTimeout(function (ref) {
            ref._pResizeSubject.notify(clientData);
        }, 150, this);
        //this._pResizeSubject.notify(clientData);
    }

};


function getClientDataMiddleWare () {

    var w = window,
        d = document,
        e = d.documentElement,
        g = d.getElementsByTagName('body')[0];
    var clientData = {};
    clientData.windowWidth = w.innerWidth || e.clientWidth || g.clientWidth;
    clientData.windowHeight = w.innerHeight || e.clientHeight || g.clientHeight;
    clientData.devicePixelRatio = window.devicePixelRatio || 1;

    try {

        var d1 = new Date();
        clientData.localDateTime = d1.toString();
        clientData.timeStampEpoch = Math.ceil(Date.now() / 1000);
        clientData.timezoneOffset = d1.getTimezoneOffset();
        //clientData.URL = window.location.toString();
        if (window.event) {
            var element = window.event.srcElement || window.event.currentTarget;
            if (element) {
                clientData.SourceElement = {};
                clientData.SourceElement.id = element.id;
                clientData.SourceElement.className = element.className;
                clientData.SourceElement.innerText = element.innerText;
                clientData.SourceElement.offsetTop = element.offsetTop;
                clientData.SourceElement.offsetWidth = element.offsetWidth;
            }
        }
        // if (window.navigator) {
        //     var nav = {};
        //     nav.language = window.navigator.language;
        //     if (window.navigator.languages) {
        //         nav.languages = window.navigator.languages.toString();
        //     }
        //     if (window.navigator.hardwareConcurrency) {
        //         nav.hardwareConcurrency = window.navigator.hardwareConcurrency;
        //     }

        //     nav.userAgent = window.navigator.userAgent;
        //     nav.platform = window.navigator.platform;
        //     nav.product = window.navigator.product;
        //     nav.vendor = window.navigator.vendor;
        //     clientData.Navigator = nav;
        // }

    } catch (ex) {
        //TODO: log exception
    }

    return clientData;
}

function getNav () {
    try {
        if (window.navigator) {
            var nav = {};
            nav.language = window.navigator.language;
            if (window.navigator.languages) {
                nav.languages = window.navigator.languages.toString();
            }
            if (window.navigator.hardwareConcurrency) {
                nav.hardwareConcurrency = window.navigator.hardwareConcurrency;
            }

            nav.userAgent = window.navigator.userAgent;
            nav.platform = window.navigator.platform;
            nav.product = window.navigator.product;
            nav.vendor = window.navigator.vendor;

            //nav.maxTouchPoints = window.navigator.maxTouchPoints;

            nav.appCodeName = window.navigator.appCodeName;
            nav.appName = window.navigator.appName;
            nav.appVersion = window.navigator.appVersion;
            nav.connection = getCon();
            return nav;
        }

    } catch (ex) {
        //TODO: log exception
    }
    return null;
}

function getCon () {
    try {
        if (window.navigator.connection) {

            var con = {};
            con.downlink = window.navigator.connection.downlink;
            con.effectiveType = window.navigator.connection.effectiveType;
            con.onchange = window.navigator.connection.onchange;
            con.rtt = window.navigator.connection.rtt;
            con.saveData = window.navigator.connection.saveData;
            return con;
        }
    } catch (error) {

    }

    return null;

}



var _headerBaseURL = undefined;

function GetBaseUrlFromDocumentMiddleware () {

    if (!_headerBaseURL) {
        if (window.$sfConfigModel !== undefined && window.$sfConfigModel.BASE_URL !== undefined) {
            _headerBaseURL = window.$sfConfigModel.BASE_URL;
        } else {
            //_baseURL = document.getElementsByTagName('base')[0].href;
            _headerBaseURL = document.getElementsByTagName('base')[0].attributes.href.value;
        }

    }
    return _headerBaseURL;
}

var _scriptsDirectory = undefined;
function getScriptsDirectory () {
    if (_scriptsDirectory) {
        return _scriptsDirectory;
    } else {
        if (window.$sfConfigModel !== undefined) {
            if (window.$sfConfigModel.EnvId === 8) {
                _scriptsDirectory = 'prod';
            } else {
                _scriptsDirectory = 'dist';
            }

        } else {
            if (window.location.host.indexOf('v8builder.com') > -1) {
                _scriptsDirectory = 'prod';
            } else {
                _scriptsDirectory = 'dist';
            }
        }
        return _scriptsDirectory;
    }
}


var _webWorkerMiddleware = undefined;
function getWebWorkerMiddleawre () {
    if (!_webWorkerMiddleware) {
        if (window.Worker) {

            _webWorkerMiddleware = new Worker(GetBaseUrlFromDocumentMiddleware() + getScriptsDirectory() + "/js/WebWorkerMiddleware.js");
        }
    }
    return _webWorkerMiddleware;
}


//@ts-check
function ClientToServerUpdateTask (e, dataModel) {
    try {
        var userWrapper = {};
        userWrapper.type = "ClientDataWrapper";
        userWrapper.ClientData = getClientDataMiddleWare();
        userWrapper.PAGE_URL = window.location.toString();
        userWrapper.Navigator = getNav();

        if (dataModel) {
            userWrapper.DataModel = {};
            userWrapper.DataModel.type = "DataModel";
            userWrapper.DataModel.value = dataModel;
        }

        setTimeout(function () {
            var theURL = GetBaseUrlFromDocumentMiddleware() + "ClientServices/Update";
            postToServer(userWrapper, theURL);
        }, 300);

        // // setTimeout(function () {
        // //     var theURL = GetBaseUrlFromDocumentMiddleware() + "ClientServices/Update";
        // //     if (typeof (Worker) !== "undefined") {
        // //         // Web worker supported!
        // //         var webWorker = getWebWorkerMiddleawre();
        // //         var dataMsgForWorker = [
        // //             "PostDataFromWorker",
        // //             theURL,
        // //             userWrapper
        // //         ];
        // //         //webWorker.addEventListener('message', handleMessageFromWorker);
        // //         webWorker.postMessage(dataMsgForWorker);
        // //         //webWorker.onmessage = function (e) {
        // //         //    var resultContent = e.data;
        // //         //    console.log('Message received from worker');
        // //         //    webWorker.terminate();
        // //         //};

        // //     } else {
        // //         postToServer(userWrapper, theURL);
        // //     }
        // // }, 300);


    } catch (exception) {
        //TODO: log excepotion
    }



    function postToServer (userWrapper, theURL) {
        //@ts-check
        var suppliedHeaders = {}
        suppliedHeaders['Content-Type'] = 'application/json';
        // Sorry! No Web Worker support..
        fetch(theURL, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'same-origin', // no-cors, cors, *same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: suppliedHeaders,
            redirect: 'follow', // manual, *follow, error
            referrer: 'client', // no-referrer, *client
            body: JSON.stringify(userWrapper), // body data type must match "Content-Type" header
        }).then(value => {
            var response = value;
            return response.json();
        });
    }

    function handleMessageFromWorker (msg) {
        console.log('incoming message from worker, msg:', msg);

    }


}

(function () {
    window.$GM = window.$GlobalMiddleware = new GlobalMiddleware();

    setTimeout(function () {
        ClientToServerUpdateTask();
    }, 350);
})();
